import {
    CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    IAny,
    ICreateActionContext,
    IGeneric
} from '@msdyn365-commerce/core';
import { getTenderTypesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/StoreOperationsDataActions.g';
import { TenderType } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { QueryResultSettingsProxy } from '@msdyn365-commerce-modules/retail-actions';

/**
 * Action input.
 */
export class GetTenderTypesInput implements IActionInput {
    public getCacheKey = () => 'TenderTypes';
    public getCacheObjectType = () => 'TenderTypes';
    public dataCacheType = (): CacheType => 'request';
}
/**
 * CreateInput method for the getTenderTypes method.
 * @param inputData -- The input data passed to the createInput method.
 * @returns - Input for data action.
 */
export const createTenderTyperInput = (inputData: ICreateActionContext<IGeneric<IAny>>): IActionInput => {
    return new GetTenderTypesInput();
};
/**
 * Get tender type action.
 * @param input - Input data.
 * @param context - Action context.
 * @returns - TenderType
 */
export async function getTenderTypeAction(input: GetTenderTypesInput, context: IActionContext): Promise<TenderType[]> {
    const tenderType = await getTenderTypesAsync({
        callerContext: context,
        queryResultSettings: QueryResultSettingsProxy.getPagingFromInputDataOrDefaultValue(context)
    });
    return tenderType;
}

/**
 * Get Tender Types Data Action.
 */
export const getTenderTypeActionDataAction = createObservableDataAction<TenderType[]>({
    id: '@msdyn365-commerce-modules/order-management/get-tender-types',
    action: getTenderTypeAction as IAction<TenderType[]>,
    input: createTenderTyperInput
});
export default getTenderTypeActionDataAction;
