import { createObservableDataAction, IAction, ICreateActionContext } from '@msdyn365-commerce/core';
import { retailAction } from '@msdyn365-commerce/retail-proxy';
import { createVSI_GetCategoriesInput } from '../custom-retail-extension-proxies/DataActionExtension.g';
import { IVSICategory } from '../custom-retail-extension-proxies/DataServiceEntities.g';

export default createObservableDataAction({
    action: <IAction<IVSICategory[]>>retailAction,
    input: (context: ICreateActionContext) => {
        const {
            requestContext: {
                apiSettings: { channelId }
            }
        } = context;
        return createVSI_GetCategoriesInput({ Paging: { Top: 500 } }, channelId);
    }
});
