import { buildCacheKey, getSimpleProducts, ProductInput } from '@msdyn365-commerce-modules/retail-actions';
import {
    CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    ICommerceApiSettings,
    ICreateActionContext
} from '@msdyn365-commerce/core';
import { SalesOrder, SimpleProduct, FeatureState, CatalogsDataActions } from '@msdyn365-commerce/retail-proxy';
import {
    getSalesOrderDetailsBySalesIdAsync,
    getSalesOrderDetailsByTransactionIdAsync
} from '@msdyn365-commerce/retail-proxy/dist/DataActions/SalesOrdersDataActions.g';
import { getFeatureStatesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/StoreOperationsDataActions.g';
interface ISalesOrderWithHydrations {
    salesOrder: SalesOrder;
    products: SimpleProduct[];
}
/**
 * Product and Catalog Interface.
 */
interface IProductCatalog {
    productId: number;
    catalogId: number | undefined;
}

/**
 *  orderTypes Types of orders
 */
export const enum orderTypes {
    salesOrder = 'salesOrder',
    transaction = 'transaction'
}

/**
 * Calls the Retail API and returns the sales order
 */
const getSalesOrder = (orderType: string = '', orderId: string = '') => async (ctx: IActionContext): Promise<SalesOrder> => {
    return orderType === orderTypes.salesOrder
        ? getSalesOrderDetailsBySalesIdAsync({ callerContext: ctx }, orderId)
        : //  Local (1) searches the retail server database, and remote (2) searches
          // on the headquarters side. All (3) and none (0) are not supported.
          getSalesOrderDetailsByTransactionIdAsync({ callerContext: ctx }, orderId, 3);
};

/**
 * Calls the Retail API and returns the products
 */
const getProductsWithCatalog = (productCatalog: IProductCatalog[], channelId?: number) => async (
    context: IActionContext
): Promise<SimpleProduct[]> => {
    const productInputs = productCatalog.map(
        index =>
            new ProductInput(
                index.productId,
                context.requestContext.apiSettings,
                channelId,
                undefined,
                context.requestContext,
                index.catalogId
            )
    );
    return getSimpleProducts(productInputs, context);
};

/**
 *  Action input
 */
export class GetSalesOrderWithHydrationsInput implements IActionInput {
    public orderType: string;
    public orderId: string;
    private apiSettings: ICommerceApiSettings;

    constructor(orderType: string, orderId: string, apiSettings: ICommerceApiSettings) {
        this.orderType = orderType;
        this.orderId = orderId;
        this.apiSettings = apiSettings;
    }

    public getCacheKey = () => buildCacheKey(`SalesOrderWithHydrations`, this.apiSettings);
    public getCacheObjectType = () => `SalesOrderWithHydrations-${this.orderType}-${this.orderId}`;
    public dataCacheType = (): CacheType => 'request';
}

/**
 * Creates the input required to make the retail api call
 */
const createSalesOrderWithHydrationsInput = (inputData: ICreateActionContext) => {
    const { salesId = '', transactionId = '' } = (inputData.requestContext.query && inputData.requestContext.query) || {};
    if (salesId) {
        return new GetSalesOrderWithHydrationsInput(orderTypes.salesOrder, salesId, inputData.requestContext.apiSettings);
    } else if (transactionId) {
        return new GetSalesOrderWithHydrationsInput(orderTypes.transaction, transactionId, inputData.requestContext.apiSettings);
    }
    throw new Error(`createSalesOrderWithHydrationsInput - No salesId or transactionId provided.`);
};

/**
 * Get sales order with hydrations action
 */
export async function getSalesOrderWithHydrationsAction(
    input: GetSalesOrderWithHydrationsInput,
    ctx: IActionContext
): Promise<ISalesOrderWithHydrations> {
    if (!ctx) {
        throw new Error(`getSalesOrderWithHydrationsAction - Action context cannot be null/undefined`);
    }

    const { orderType, orderId } = input;

    if (!orderType || !orderId) {
        ctx.trace(`[getSalesOrderWithHydrationsAction] No orderType or orderId provided.`);
        return <ISalesOrderWithHydrations>{};
    }

    const salesOrder = await getSalesOrder(orderType, orderId)(ctx);

    if (!salesOrder || !salesOrder.SalesLines || !salesOrder.SalesLines.length) {
        ctx.trace(`[getSalesOrderWithHydrationsAction] No salesLine found.`);
        return <ISalesOrderWithHydrations>{};
    }

    const productCatalogIds: IProductCatalog[] = salesOrder.SalesLines.map(salesLine => {
        return {
            productId: salesLine.ProductId !== undefined ? salesLine.ProductId : 0,
            catalogId: salesLine.CatalogId
        };
    });

    if (!productCatalogIds || !productCatalogIds.length) {
        ctx.trace(`[getSalesOrderWithHydrationsAction] No productId in saleLines found.`);
        return <ISalesOrderWithHydrations>{};
    }

    const featureStates = await getFeatureStatesAsync({ callerContext: ctx }, ['Dynamics.AX.Application.ChannelMultipleCatalogsFeature']);
    const isCatalogsFeatureEnabled =
        featureStates?.find((featureState: FeatureState) => featureState.Name === 'Dynamics.AX.Application.ChannelMultipleCatalogsFeature')
            ?.IsEnabled || false;
    let products: SimpleProduct[] = [];
    if (!isCatalogsFeatureEnabled) {
        products = await getProductsWithCatalog(productCatalogIds, salesOrder.ChannelId)(ctx);
    } else {
        // Get product catalogs for current user. Catalog ids will be stored in RecordId property of userCatalogIds.
        const userCatalogIds = await CatalogsDataActions.getCatalogsAsync(
            { callerContext: ctx },
            ctx.requestContext.apiSettings.channelId,
            true
        );
        // Filter productCatalogIds to get only those that are in userCatalogIds.
        const matchingProductCatalogIds = productCatalogIds.filter(productCatalogId => {
            return userCatalogIds.some(userCatalogId => userCatalogId.RecordId === productCatalogId.catalogId);
        });
        // Filter productCatalogIds to get only those that are NOT in userCatalogIds.
        const nonMatchingProductCatalogIds = productCatalogIds.filter(productCatalogId => {
            return userCatalogIds.some(userCatalogId => userCatalogId.RecordId !== productCatalogId.catalogId);
        });
        let matchingProducts: SimpleProduct[] = [];
        if (matchingProductCatalogIds.length !== 0) {
            matchingProducts = await getProductsWithCatalog(matchingProductCatalogIds, ctx.requestContext.apiSettings.channelId)(ctx);
        }
        let nonMatchingProducts: SimpleProduct[] = [];
        if (nonMatchingProductCatalogIds.length !== 0) {
            nonMatchingProducts = await getProductsWithCatalog(nonMatchingProductCatalogIds, salesOrder.ChannelId)(ctx);
        }
        products = [...matchingProducts, ...nonMatchingProducts];
    }

    // const products = await getProductsWithCatalog(productCatalogIds, salesOrder.ChannelId)(ctx);

    if (!products || !products.length) {
        ctx.trace(`[getSalesOrderWithHydrationsAction] No product found.`);
        return <ISalesOrderWithHydrations>{};
    }

    return <ISalesOrderWithHydrations>{
        salesOrder,
        products
    };
}

export default createObservableDataAction({
    id: '@msdyn365-commerce-modules/order-management/get-sales-order-with-hydrations',
    action: <IAction<ISalesOrderWithHydrations>>getSalesOrderWithHydrationsAction,
    input: createSalesOrderWithHydrationsInput
});
